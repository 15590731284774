import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from "vue-router";
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Swipe, SwipeItem, ImagePreview, Icon } from 'vant';
import 'vant/lib/index.css';


Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Element);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(ImagePreview);
Vue.use(Icon);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
