<template>
  <div class="home" @click="jumpPage">user</div>
</template>

<script>
export default {
  name: "App",
  components: {},
  methods: {
    jumpPage() {
      this.$router.push({ path: "/home" });
    },
  },
};
</script>

<style>
.home {
}
</style>
