<template>
  <div class="home" @click="jumpPage">home</div>
</template>

<script>
import { GetTempList } from "@/api/api";

export default {
  name: "App",
  components: {},
  created() {
    this.init();
  },
  methods: {
    async init() {
      const res = await GetTempList();
      console.log(res);
    },
    jumpPage() {
      this.$router.push({ path: "/user" });
    },
  },
};
</script>

<style>
.home {
}
</style>
